import React from 'react';
import {Link, graphql} from 'gatsby';
import Layout from '../components/Layout';
import FullScreenContainer from '../components/FullScreenContainer';
import Image from '../components/Image';

import '../styles/pages/404.scss';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <FullScreenContainer className="page-404">
          <div className="page-404__background">
            <Image image={this.props.data.backgroundImage} sizes="100vw" />
          </div>
          <div className="page-404__text">
            <h1 className="display">404</h1>
            <h2>Page Not Found</h2>
            <Link to={'/'}><button>Return Home</button></Link>
          </div>
        </FullScreenContainer>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
      backgroundImage: file(relativePath: { eq: "404/404-bg@2x.jpg" }) {
        ...largeImage
      }
  }
`;

export default NotFoundPage;
